/** 登录名设置引导弹窗 */
import { Modal, Checkbox } from '@nbit/arco'
import { t } from '@lingui/macro'
import Icon from '@/components/icon'
import { link } from '@/helper/link'
import { useState } from 'react'
import { getPersonalAccountSecurityPath } from '@/helper/route'
import { useUserStore } from '@/store/user'
import styles from './index.module.css'

function LoginNameModal(props) {
  const { setVisible, visible } = props
  const [checkBoxStatus, setCheckBoxStatus] = useState<boolean>(false)
  const { setIsShowLoginName } = useUserStore()

  /** 关闭事件 */
  const onClose = () => {
    setVisible(false)
    setIsShowLoginName(checkBoxStatus)
  }
  return (
    <div>
      <Modal
        wrapClassName="example-trade-modal"
        className={styles.scoped}
        visible={visible}
        onCancel={() => onClose()}
        autoFocus={false}
        focusLock
        footer={null}
        closable={false}
      >
        <div>
          <div className="mode-tis">
            <div className="mode-item">{t`trade.c2c.max.reminder`}</div>
            <div>
              <Icon onClick={() => onClose()} name="nav_icon_delete" />
            </div>
          </div>
          <p className="mode-p">{t`features_user_log_register_modal_component_login_name_modal_index_pks407rf0t`}</p>
          <div className="mode-box">
            <div>
              <Checkbox
                onChange={val => {
                  setCheckBoxStatus(val)
                }}
              >
                {() => {
                  return (
                    <div className="service-agreement">
                      <Icon
                        className={checkBoxStatus ? 'text-auxiliary_color_01' : 'text-icon_color_02'}
                        name={checkBoxStatus ? 'icon_agree_yes' : 'icon_agree_yes'}
                      />
                    </div>
                  )
                }}
              </Checkbox>
            </div>
            <div className="box-text">{t`features_user_log_register_modal_component_login_name_modal_index_dbal104xsd`}</div>
          </div>
          <div className="mode-button">
            <div className="mode-button-text" onClick={() => onClose()}>
              {t`user.field.reuse_48`}
            </div>
            <div
              className="mode-button-go"
              onClick={() => {
                setVisible(false)
                link(getPersonalAccountSecurityPath())
                setIsShowLoginName(checkBoxStatus)
              }}
            >
              {t`user.account_security.google_01`}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default LoginNameModal
