import { useEffect, useState } from 'react'
import { useRequest, useMount } from 'ahooks'
import { Carousel } from '@nbit/arco'
import { BannerListTypeProps } from '@/typings/api/game'
import { getGameBannerList } from '@/apis/game'
import { RequestCacheKeyEnum, RequestCacheTimeEnum } from '@/constants/common'
import { GetBannerSpecialHandlingEnum } from '@/constants/user'
import { useUserStore } from '@/store/user'
import { useAssetsFiatStore } from '@/store/assets/fiat'
import { DepositSidebarSetting } from '@/features/user/components/sidebar'
import Icon from '@/components/icon'
import LoginNameModal from '@/features/user/log-register-modal/component/login-name-modal'
import { getIsDemoUser } from '@/helper/user'
import styles from './index.module.css'

type DefaultCurrentBannerData = {
  dataList: BannerListTypeProps[]
}[]

export default function HomePageBanner2z() {
  const [visible, setVisible] = useState(false)

  const { faitEnums, faitFuturesEnums } = useAssetsFiatStore() || {}
  const { isLogin, setLogRegisterVisible, isShowLoginName, userInfo, updateUserInfoData } = useUserStore()
  const [loginNameVisible, setLoginNameVisibleVisible] = useState<boolean>(false)
  const paymentColor = faitEnums.c2cPaymentColorEnum.enums
  const paymentMethod = faitEnums.paymentDicCodeEnum.enums
  const isShow = isLogin && !getIsDemoUser() && !isShowLoginName && !userInfo?.loginName

  /** 获取 banner */
  const getHomeBasiceList = async () => {
    const { data: bannerData, isOk: isBannerOk } = await getGameBannerList({})
    if (isBannerOk && bannerData) {
      const chunkSize = 3
      const chunkedArray = [] as DefaultCurrentBannerData
      let previousDataList: BannerListTypeProps[] = []

      for (let i = 0; i < bannerData.length; i += chunkSize) {
        const chunk = {
          dataList: bannerData.slice(i, i + chunkSize) as BannerListTypeProps[],
        }

        // 检查 dataList 的长度是否小于 chunkSize
        if (chunk.dataList.length < chunkSize) {
          // 如果最后一个 dataList 不足三个对象，则从前一个 dataList 中取图填充
          const remainingItems = chunkSize - chunk.dataList.length
          const fillItems = previousDataList.slice(-remainingItems)
          chunk.dataList.unshift(...fillItems)
        }

        chunkedArray.push(chunk)
        previousDataList = chunk.dataList
      }
      return chunkedArray
    }
    return []
  }

  const { run: bannerRun, data: currentBannerData } = useRequest(getHomeBasiceList, {
    manual: true,
    cacheKey: RequestCacheKeyEnum.homeBanner,
    cacheTime: RequestCacheTimeEnum.homeBanner,
  })

  useMount(faitFuturesEnums)

  useMount(bannerRun)

  /** 点击 banner */
  const onCarouselChange = src => {
    if (src?.webTargetUrl === GetBannerSpecialHandlingEnum.currencyRecharge) {
      isLogin ? setVisible(true) : setLogRegisterVisible(true)
      return
    }
    window.location.href = src?.webTargetUrl
  }

  /** 登录名设置弹窗展示方法 */
  const handleLoginNameMethod = async () => {
    await updateUserInfoData()
    setLoginNameVisibleVisible(true)
  }

  useEffect(() => {
    if (isShow) {
      handleLoginNameMethod()
    }
  }, [isLogin])

  return (
    <div className={styles.scoped}>
      {!currentBannerData?.length ? <div className="no-data-carousel" /> : null}

      <Carousel
        autoPlay
        className="home-header-carousel"
        arrowClassName={styles.arrow}
        icons={{
          prev: <Icon name="icon_arrow_banner_left_v2" />,
          next: <Icon name="icon_arrow_banner_right_v2" />,
        }}
      >
        {currentBannerData?.map((item, index) => (
          <div className="header-carousel" key={index}>
            {item?.dataList &&
              item?.dataList.length > 0 &&
              item?.dataList.map((src, srcIndex) => (
                <img
                  key={srcIndex}
                  src={src?.webImage}
                  onClick={() => onCarouselChange(src)}
                  className={`item-image ${srcIndex === 1 ? 'center-image' : ''}`}
                  alt=""
                />
              ))}
          </div>
        ))}
      </Carousel>

      {visible && (
        <DepositSidebarSetting
          visible={visible}
          setVisible={setVisible}
          paymentDealType={paymentMethod}
          paymentColor={paymentColor}
        />
      )}

      {isLogin && !getIsDemoUser() && !isShowLoginName && (
        <LoginNameModal visible={loginNameVisible} setVisible={setLoginNameVisibleVisible} />
      )}
    </div>
  )
}
